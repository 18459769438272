import React from "react";
import { MapContainer, TileLayer, useMap, Marker, Popup } from "react-leaflet";
import * as contactStyles from "../../styles/contact.module.css";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css"; // Re-uses images from ~leaflet package
import * as L from "leaflet";
import "leaflet-defaulticon-compatibility";

const MapWrapper = () => {
  if (typeof window !== "undefined") {
    return (
      <MapContainer
        className={contactStyles.mapStyle}
        center={[33.738045, 73.084488]}
        zoom={13}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={[33.738045, 73.084488]}>
          <Popup>TeckRevo</Popup>
        </Marker>
      </MapContainer>
    );
  } else return null;
};

export default MapWrapper;
