import React from "react";
import TypeWriter from "typewriter-effect";
import * as typeWriterStyle from "../../styles/home.module.css";
type Props = {
  wordsToAnimate: Array<string>;
};
export default function TextAnimation(props: Props) {
  return (
    <TypeWriter
      options={{
        strings: props.wordsToAnimate,
        autoStart: true,
        loop: true,
      }}
      onInit={(typewriter) => {
        typewriter.pauseFor(1000).deleteAll().start();
      }}
    />
  );
}
