import { Box, Container, Grid } from "@mui/material";
import React from "react";
import * as servicesStyles from "../../styles/services.module.css";
import { BsCodeSlash, BsLaptop } from "react-icons/bs";
import { IoLayersOutline } from "react-icons/io5";
import { AiOutlineMobile } from "react-icons/ai";
import { MdSupport } from "react-icons/md";
import { motion, Variants } from "framer-motion";
import ServicesCard from "./ServicesCard";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

export default function ServicesSection() {
  const servicesImgData = useStaticQuery(graphql`
    query ImageQuery {
      file(relativePath: { eq: "services_img.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const dropFromAbove: Variants = {
    offscreen: {
      y: -100,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.6,
        duration: 0.9,
      },
    },
  };
  return (
    // <Container className={servicesStyles.container}>
    <Container className={servicesStyles.container}>
      <Box className={servicesStyles.headingBox}>
        <motion.h2
          initial={{ scale: 0.1 }}
          whileInView={{
            scale: 1,
            transition: {
              type: "spring",
              bounce: 0.4,
              duration: 0.8,
            },
          }}
          viewport={{ once: true, amount: 0.4 }}
          className={servicesStyles.sectionHeading}
        >
          What we offer?
        </motion.h2>
        <p className={servicesStyles.sectionTag}>
          Through end-to-end development, TeckRevo enables you to solve the
          challenges of website and mobile app development.
        </p>
      </Box>
      <Grid container className={servicesStyles.gridContainer}>
        <Box
          component={Grid}
          xl={4}
          lg={4}
          md={4}
          sm={0}
          xs={0}
          item
          display={{
            xl: "block",
            lg: "block",
            md: "block",
            sm: "none",
            xs: "none",
          }}
          spacing={2}
        >
          <motion.div
            style={{ width: "100%", height: "100%" }}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.4 }}
            variants={dropFromAbove}
          >
            <Img
              className={servicesStyles.image}
              fluid={servicesImgData.file.childImageSharp.fluid}
            />
          </motion.div>
        </Box>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <ServicesCard
                Icon={BsCodeSlash}
                title="Web Development"
                desc="We have achieved milestones in underlying best web development services. At TeckRevo, expert developers use all updated tools to serve clients with exactly demanded services. Our diverse portfolio clearly highlights what we have known for."
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <ServicesCard
                Icon={AiOutlineMobile}
                title="Mobile App Development"
                desc="We at TeckRevo help you tap your audience and achieve your goals via mobile app. In this competitive world, where one mistake can destroy your kingdom, we help you build world standard mobile apps with the best user experience."
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <ServicesCard
                Icon={MdSupport}
                title="Technical Support"
                desc="Providing Technical support for your product, let us deal with the clients, log their concerns in a ticketing system and follow up with your team till it’s resolved."
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <ServicesCard
                Icon={BsLaptop}
                title="System Integration"
                desc="We bring together the disparate components of your digital infrastructure into a cohesive system and ensure that they work together seamlessly to meet your objectives."
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
