import React, { useEffect, useState } from "react";
import { Box, Container, Grid } from "@mui/material";
import * as homeStyles from "../../styles/home.module.css";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import TextAnimation from "../../utils/animations/TextAnimation";
export default function HomeSection() {
  const [placeholder, setPlaceholder] = useState("|");
  const wordsToAnimate = ["efficient", "secure", "scalable", "cost-effective"];
  // useEffect(()=>{
  //   function animate(word){
  //     word.map((letter,index)=>{
  //       setPlaceholder(prev=>)

  //     })

  //   }

  // },[placeholder])
  const bannerImgData = useStaticQuery(graphql`
    query MyQuery {
      file(relativePath: { eq: "banner_img.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  console.log("Image data is", bannerImgData);
  return (
    <Container className={homeStyles.container}>
      <Grid container className={homeStyles.gridContainer}>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          style={customStyles.leftGrid}
        >
          <Box className={homeStyles.leftTextContainer}>
            <Box>
              <h2 className={homeStyles.sectionHeading}>
                Building awe-inspiring digital products that are
              </h2>
              <h2 className={homeStyles.animatedText}>
                <TextAnimation wordsToAnimate={wordsToAnimate} />
              </h2>
              <h2 className={homeStyles.sectionHeading}>and launch ready.</h2>
            </Box>

            <p className={homeStyles.sectionTag}>
              Aggregated skill set of our experienced employees and high caliber
              of understanding the client’s requirement is what makes us the
              best in business.
            </p>
          </Box>
        </Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          style={customStyles.rightGrid}
        >
          <Box>
            <Img
              className={homeStyles.image}
              fluid={bannerImgData.file.childImageSharp.fluid}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

const customStyles = {
  leftGrid: {
    // backgroundColor: "yellow",
  },
  rightGrid: {
    // backgroundColor: "green",
  },
};
