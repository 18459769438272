// extracted by mini-css-extract-plugin
export var container = "contact-module--container--TSAvz";
export var gridContainer = "contact-module--gridContainer---KIbs";
export var gridItem = "contact-module--gridItem--BovK7";
export var gridItemImg = "contact-module--gridItemImg--jYQC0";
export var headingBox = "contact-module--headingBox--ddWq+";
export var image = "contact-module--image--5u3sF";
export var leftGridBox = "contact-module--leftGridBox--+MQTf";
export var mapStyle = "contact-module--mapStyle--3UjQu";
export var sectionHeading = "contact-module--sectionHeading--ZP7HS";
export var sectionTag = "contact-module--sectionTag--71YII";
export var subHeading = "contact-module--subHeading--GvdCF";