import React from "react";
import AppLayout from "../layouts/app-layout/AppLayout";
import * as pageStyles from "../styles/page.module.css";
import HomeSection from "../components/home-section/HomeSection";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import ServicesSection from "../components/services-section/ServicesSection";
import AboutSection from "../components/about-section/AboutSection";
import TechSection from "../components/techstack-section/TechSection";
import ContactSection from "../components/contact-section/ContactSection";
import * as servicesStyles from "../styles/services.module.css";
import { Box } from "@mui/material";

type Props = {
  data: any;
};

export default function index(props: Props) {
  console.log("Background Image data is", props.data);
  return (
    <AppLayout>
      <section className={pageStyles.sectionContainerHome} id="home">
        {/* <Img
          className={pageStyles.backgroundImg}
          fluid={props.data.file.childImageSharp.fluid}
        /> */}
        <HomeSection />
      </section>
      <section className={pageStyles.sectionContainerServices} id="services">
        <svg
          className={servicesStyles.svg__services}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#ffffff"
            id="down_bg_copy_2"
            data-name="down / bg copy 2"
            d="M444.936,252.606c-148.312,0-305.161-29.63-444.936-80.214V0H1920V12S808.194,234.074,444.936,252.606Z"
          ></path>
        </svg>

        <ServicesSection />
      </section>
      <section className={pageStyles.sectionContainerAbout} id="about">
        <AboutSection />
      </section>
      <section className={pageStyles.sectionContainerTech} id="tech-stack">
        <TechSection />
      </section>
      <section className={pageStyles.sectionContainerContact} id="contact">
        <ContactSection />
      </section>
    </AppLayout>
  );
}

export const query = graphql`
  query bg {
    file(relativePath: { eq: "home-bg.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
