import { Box, Container, Grid } from "@mui/material";
import React from "react";
import * as aboutStyles from "../../styles/about.module.css";
import { graphql, useStaticQuery } from "gatsby";
import { MdSupport, MdOutlineSpeakerNotes } from "react-icons/md";
import Img from "gatsby-image";
import { motion, Variants } from "framer-motion";
import AboutCard from "./AboutCard";
export default function AboutSection() {
  const aboutImgData = useStaticQuery(graphql`
    query aboutImageQuery {
      who_are_we_img: file(relativePath: { eq: "who_are_we.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      why_choose_us_img: file(relativePath: { eq: "why_choose_us.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      our_team_img: file(relativePath: { eq: "our_team.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      perfection_img: file(relativePath: { eq: "perfection.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  console.log("About image data is", aboutImgData);

  const leftVariants: Variants = {
    offscreen: {
      x: -200,
      opacity: 0,
    },
    onscreen: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8,
      },
    },
  };
  const rightVariants: Variants = {
    offscreen: {
      x: 200,
      opacity: 0,
    },
    onscreen: {
      x: 0,
      opacity: 1,

      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8,
      },
    },
  };
  return (
    <Container className={aboutStyles.container}>
      <Box className={aboutStyles.headingBox}>
        <motion.h2
          initial={{ scale: 0.1 }}
          whileInView={{
            scale: 1,
            transition: {
              type: "spring",
              bounce: 0.4,
              duration: 0.8,
            },
          }}
          viewport={{ once: true, amount: 0.4 }}
          className={aboutStyles.sectionHeading}
        >
          Your one-stop software engineering studio
        </motion.h2>
      </Box>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={aboutStyles.gridContainer}
      >
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={aboutStyles.gridItemImg}
        >
          {/* <div style={{ width: "100%", height: "100%" }}></div> */}
          <motion.div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.4 }}
            variants={leftVariants}
          >
            <Img
              className={aboutStyles.image}
              fluid={aboutImgData.who_are_we_img.childImageSharp.fluid}
            />
          </motion.div>
        </Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={aboutStyles.gridItemCard}
        >
          <motion.div
            style={{ width: "100%", height: "100%" }}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.4 }}
            variants={leftVariants}
          >
            <AboutCard
              Icon={MdOutlineSpeakerNotes}
              title="Who are we?"
              desc="TeckRevo is a product innovation and software development organization. It acquires, manages and builds market-leading software businesses that develop industry-specific, mission-critical enterprise software solutions to serve both the public and private sectors. "
            />
          </motion.div>
        </Grid>
        <Grid
          container
          direction={{
            xl: "row",
            lg: "row",
            md: "row",
            sm: "column-reverse",
            xs: "column-reverse",
          }}
        >
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={aboutStyles.gridItemCard}
          >
            <motion.div
              style={{ width: "100%", height: "100%" }}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.4 }}
              variants={rightVariants}
            >
              <AboutCard
                Icon={MdSupport}
                title="Why choose us?"
                desc="What makes TeckRevo stand out is its ability to assist clients and meet challenges in a diversified manner across crucial touchpoints. TeckRevo offers a complete suite of application and software development services, and we would love to partner with you."
              />
            </motion.div>
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={aboutStyles.gridItemImg}
          >
            <motion.div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.4 }}
              variants={rightVariants}
            >
              <Img
                className={aboutStyles.image}
                fluid={aboutImgData.why_choose_us_img.childImageSharp.fluid}
              />
            </motion.div>
          </Grid>
        </Grid>

        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={aboutStyles.gridItemImg}
        >
          <motion.div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.4 }}
            variants={leftVariants}
          >
            <Img
              className={aboutStyles.image}
              fluid={aboutImgData.our_team_img.childImageSharp.fluid}
            />
          </motion.div>
        </Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={aboutStyles.gridItemCard}
        >
          <motion.div
            style={{ width: "100%", height: "100%" }}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.4 }}
            variants={leftVariants}
          >
            <AboutCard
              Icon={MdSupport}
              title="Our team"
              desc="Our team blends high-value stateside product managers, UX/UI designers and architects with our global development workforce to yield maximum returns for clients. At TeckRevo, we believe in contributing positively to society through business value creation."
            />
          </motion.div>
        </Grid>
        <Grid
          container
          direction={{
            xl: "row",
            lg: "row",
            md: "row",
            sm: "column-reverse",
            xs: "column-reverse",
          }}
        >
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={aboutStyles.gridItemCard}
          >
            <motion.div
              style={{ width: "100%", height: "100%" }}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.4 }}
              variants={rightVariants}
            >
              <AboutCard
                Icon={MdSupport}
                title="We strive for perfection"
                desc=" We take pride in our amazing and sought after team of software developers, quality assurance engineers, designers and scrum masters committed to strive for excellence."
              />
            </motion.div>
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={aboutStyles.gridItemImg}
          >
            <motion.div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.4 }}
              variants={rightVariants}
            >
              <Img
                className={aboutStyles.image}
                fluid={aboutImgData.perfection_img.childImageSharp.fluid}
              />
            </motion.div>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
