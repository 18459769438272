import { Box, Typography } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";
type Props = {
  Icon: any;
  title: string;
  desc: string;
};
const AboutCard: React.FC<Props> = ({ Icon, title, desc }) => {
  const [hovering, setHovering] = useState(false);
  return (
    <Box
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      sx={styles.cardContainer}
    >
      <Icon style={hovering ? styles.hoverIcon : styles.icon} />
      <Typography sx={hovering ? styles.hoverTitle : styles.title}>
        {title}
      </Typography>
      <Typography sx={styles.desc}>{desc}</Typography>
    </Box>
  );
};

export default AboutCard;

const styles = {
  cardContainer: {
    width: "100%",
    maxWidth: "700px",
    // height: "100%",
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "column",
    paddingLeft: { md: "50px" },
    paddingBottom: "50px",
    transition: "all 0.2s linear",
  },
  icon: {
    height: "100%",
    width: "100%",
    maxHeight: "70px",
    maxWidth: "70px",
    padding: "10px",
    marginBottom: "5px",
    color: "#5cc2ef",
    border: "1px solid rgba(94,44,237,.2)",
    borderRadius: "4px",
    backgroundColor: "rgba(94,44,237,.05)",
    transition: "all 0.2s linear",
  },
  hoverIcon: {
    height: "100%",
    width: "100%",
    maxHeight: "70px",
    maxWidth: "70px",
    padding: "10px",
    color: "#fff",
    backgroundColor: "#5cc2ef",

    marginBottom: "5px",
  },
  title: {
    fontSize: { xl: "30px", lg: "30px", md: "30px", sm: "24px", xs: "24px" },
    lineHeight: "26px",
    color: "#282835",
    fontWeight: 600,
    paddingBottom: "5px",
    transition: "all 0.2s linear",
    marginBottom: "20px",
    marginTop: "20px",
    fontFamily: "Poppins",
  },
  hoverTitle: {
    fontSize: { xl: "30px", lg: "30px", md: "30px", sm: "24px", xs: "24px" },
    lineHeight: "26px",
    color: "#5cc2ef",
    fontWeight: 600,
    paddingBottom: "5px",
    marginBottom: "20px",
    marginTop: "20px",
    fontFamily: "Poppins",
  },
  desc: {
    color: "#8b8b99",
    fontSize: "18px",
    lineHeight: "30px",
    fontWeight: 400,
    textAlign: "justify",
    paddingRight: "10px",
  },
};
