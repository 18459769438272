import { Container, IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import useScrollPosition from "../../hooks/useScrollPosition";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import { Box } from "@mui/system";
import { motion, useScroll } from "framer-motion";

// type MobileMenuProps = {
//   anchorEl: null | HTMLElement;
//   open: boolean;
//   handleClose: () => void;
//setActiveLink: React.SetStateAction<string>;
// };
type MobileMenuProps = {
  showMobileMenu: boolean;
  activeLink: string;
  setActiveLink: any;
  setShowMobileMenu: any;
};

const Navbar = () => {
  const [activeLink, setActiveLink] = useState("Home");
  const scrollPosition = useScrollPosition();
  const { height, width } = useWindowDimensions();
  const logoImgData = useStaticQuery(graphql`
    query LogoQuery {
      file(relativePath: { eq: "techrevo-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  console.log("Logo img data", logoImgData);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { scrollYProgress } = useScroll();
  return (
    <Box sx={scrollPosition <= 5 ? styles.navStyle : styles.navScrolling}>
      <Container sx={styles.innerContainer}>
        <div style={styles.logoContainer}>
          <Img
            style={styles.logoStyle}
            fluid={logoImgData.file.childImageSharp.fluid}
          />
          <h3 style={styles.title}>
            Teck<span style={{ color: "#5cc2ef" }}>Revo</span>
          </h3>
        </div>

        <Box sx={styles.linkContainer}>
          <a
            href="#home"
            style={activeLink === "Home" ? styles.activeLink : styles.link}
            onClick={() => setActiveLink("Home")}
          >
            Home
          </a>
          <a
            href="#services"
            style={activeLink === "Services" ? styles.activeLink : styles.link}
            onClick={() => setActiveLink("Services")}
          >
            Services
          </a>
          <a
            href="#about"
            style={activeLink === "About" ? styles.activeLink : styles.link}
            onClick={() => setActiveLink("About")}
          >
            About Us
          </a>
          <a
            href="#tech-stack"
            style={activeLink === "Tech" ? styles.activeLink : styles.link}
            onClick={() => setActiveLink("Tech")}
          >
            Our Tech Stack
          </a>
          <a
            href="#contact"
            style={activeLink === "Contact" ? styles.activeLink : styles.link}
            onClick={() => setActiveLink("Contact")}
          >
            Contact Us
          </a>
        </Box>
        <IconButton
          sx={styles.hamburger}
          // id="basic-button"
          // aria-controls={open ? "basic-menu" : undefined}
          // aria-haspopup="true"
          // aria-expanded={open ? "true" : undefined}
          onClick={() => setShowMobileMenu(!showMobileMenu)}
        >
          <GiHamburgerMenu color="#5cc2ef" />
        </IconButton>
      </Container>
      <div style={{ position: "relative" }}>
        <MobileMenu
          showMobileMenu={showMobileMenu}
          activeLink={activeLink}
          setActiveLink={setActiveLink}
          setShowMobileMenu={setShowMobileMenu}
        />
      </div>
      {/* <motion.div
        style={{
          scaleX: scrollYProgress,
          position: "fixed",
          top: 300,
          left: 0,
          right: 0,
          height: "10px",
          background: "var(--red)",
          zIndex: 50,
          transformOrigin: "0%",
        }}
      /> */}
    </Box>
  );
};

export default Navbar;

// const MobileMenu: React.FC<MobileMenuProps> = ({
//   anchorEl,
//   open,
//   handleClose,
// }) => {
//   return (
//     <Menu
//       id="basic-menu"
//       anchorEl={anchorEl}
//       open={open}
//       onClose={handleClose}
//       MenuListProps={{
//         "aria-labelledby": "basic-button",
//       }}
//     >
//       <MenuItem onClick={handleClose}>Profile</MenuItem>
//       <MenuItem onClick={handleClose}>My account</MenuItem>
//       <MenuItem onClick={handleClose}>Logout</MenuItem>
//     </Menu>
//   );
// };
const MobileMenu: React.FC<MobileMenuProps> = ({
  showMobileMenu,
  activeLink,
  setActiveLink,
  setShowMobileMenu,
}) => {
  function handleClickMob(link: string) {
    setActiveLink(link);
    setShowMobileMenu(false);
  }
  return (
    <Box sx={showMobileMenu ? styles.showMob : styles.hideMob}>
      <a
        href="#home"
        style={activeLink === "Home" ? styles.activeLinkMob : styles.linkMob}
        onClick={() => handleClickMob("Home")}
      >
        Home
      </a>
      <a
        href="#services"
        style={
          activeLink === "Services" ? styles.activeLinkMob : styles.linkMob
        }
        onClick={() => handleClickMob("Services")}
      >
        Services
      </a>
      <a
        href="#about"
        style={activeLink === "About" ? styles.activeLinkMob : styles.linkMob}
        onClick={() => handleClickMob("About")}
      >
        About Us
      </a>
      <a
        href="#tech-stack"
        style={activeLink === "Tech" ? styles.activeLinkMob : styles.linkMob}
        onClick={() => handleClickMob("Tech")}
      >
        Our Tech Stack
      </a>
      <a
        href="#contact"
        style={activeLink === "Contact" ? styles.activeLinkMob : styles.linkMob}
        onClick={() => handleClickMob("Contact")}
      >
        Contact Us
      </a>
    </Box>
  );
};

const styles = {
  navStyle: {
    width: "100%",
    height: "100%",
    maxHeight: "73px",
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    zIndex: 10,
    top: 0,
    backgroundColor: "transparent",
    transition: "all 350ms linear",
    // boxShadow: "0 4px 10px 2px #93908D",
  },
  navScrolling: {
    width: "100%",
    height: "100%",
    maxHeight: "73px",
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    zIndex: 10,
    top: 0,
    backgroundColor: "#fff",
    boxShadow: "0 4px 15px 2px #93908D",
  },
  innerContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  logoContainer: {
    width: "100%",
    maxWidth: "180px",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    //lightBlue; #5cc2ef
    //darkBlue;#4270b6
    fontSize: "22px",
    paddingLeft: "10px",
    color: "#4270b6",
  },
  logoStyle: {
    width: "53px",
    height: "53px",
    objectFit: "contain",
  },
  linkContainer: {
    flexGrow: 1,
    display: { xs: "none", sm: "none", md: "flex", lg: "flex", xl: "flex" },
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  link: {
    color: "#4270b6",
    marginLeft: "45px",
    fontWeight: 500,
  },
  activeLink: {
    color: "#5cc2ef",
    marginLeft: "45px",
    fontWeight: 600,
  },
  hamburger: {
    display: { xs: "flex", sm: "flex", md: "none", lg: "none", xl: "none" },
  },
  showMob: {
    display: { xs: "flex", sm: "flex", md: "none", lg: "none", xl: "none" },
    flexDirection: "column",
    position: "absolute",
    bottom: -180,
    width: "100%",
    backgroundColor: "#fff",
    transition: "all 250ms linear",
  },
  hideMob: {
    display: "none",
    flexDirection: "column",
    position: "absolute",
    top: 0,
    width: "100%",
    backgroundColor: "#fff",
    transition: "all 250ms linear",
  },
  activeLinkMob: {
    color: "#5cc2ef",
    marginLeft: "45px",
    fontWeight: 600,
    paddingBottom: "5px",
    paddingTop: "5px",
  },
  linkMob: {
    color: "#4270b6",
    marginLeft: "45px",
    fontWeight: 500,
    paddingBottom: "5px",
    paddingTop: "5px",
  },
};
