import { Box, Container, IconButton } from "@mui/material";
import React from "react";
import Navbar from "../../components/navbar/Navbar";
import "../../styles/global.css";
import { FiFacebook, FiInstagram, FiLinkedin } from "react-icons/fi";
type Props = {
  children: React.ReactNode;
};

const AppLayout = (props: Props) => {
  return (
    <div className="app__layout">
      <Navbar />

      {props.children}
      <footer>
        <Container className="footer__container">
          <p>© TeckRevo 2022 All rights reserved.</p>
          <Box className="icon__container">
            <IconButton className="icon__button">
              <FiFacebook />
            </IconButton>
            <IconButton className="icon__button">
              <FiInstagram />
            </IconButton>
            <IconButton className="icon__button">
              <a
                href="https://www.linkedin.com/company/teckrevo/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FiLinkedin />
              </a>
            </IconButton>
          </Box>
        </Container>
      </footer>
    </div>
  );
};

export default AppLayout;
