import { Box, Container, Grid } from "@mui/material";
import React from "react";
import * as contactStyles from "../../styles/contact.module.css";
import MapWrapper from "./MapWrapper";
import { motion } from "framer-motion";

export default function ContactSection() {
  return (
    <Container className={contactStyles.container}>
      <Box className={contactStyles.headingBox}>
        <motion.h2
          initial={{ scale: 0.1 }}
          whileInView={{
            scale: 1,
            transition: {
              type: "spring",
              bounce: 0.4,
              duration: 0.8,
            },
          }}
          viewport={{ once: true, amount: 0.4 }}
          className={contactStyles.sectionHeading}
        >
          Reach out to us
        </motion.h2>
        <p className={contactStyles.sectionTag}>
          If you aspire to be equipped with the latest and most advanced digital
          solutions for your customers in compliance with HIPAA regulations and
          work with the best software engineering field has to offer - contact
          us today.
        </p>
      </Box>
      <Grid container className={contactStyles.gridContainer}>
        <Grid
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
          className={contactStyles.gridItem}
        >
          <Box
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <Box className={contactStyles.leftGridBox}>
              <h2 className={contactStyles.subHeading}>Office Address</h2>
              <p className={contactStyles.sectionTag}>Islamabad, Pakistan</p>
            </Box>
            <Box className={contactStyles.leftGridBox}>
              <h2 className={contactStyles.subHeading}>Contact Info</h2>
              <p className={contactStyles.sectionTag}>
                <span style={{ fontWeight: "bold" }}>Email:</span>{" "}
                taimoorali@techrevo.com
                <br />
                <span style={{ fontWeight: "bold" }}>Phone:</span>{" "}
                +92-335-0999728
              </p>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xl={8}
          lg={8}
          md={8}
          sm={12}
          xs={12}
          className={contactStyles.gridItem}
        >
          <MapWrapper />
        </Grid>
      </Grid>
    </Container>
  );
}
