import { Box, Container, Grid } from "@mui/material";
import React from "react";
import * as techStyles from "../../styles/tech.module.css";
import useTechImageQuery from "../../hooks/useTechImageQuery";
import { TechCard } from "./TechCard";
import { motion } from "framer-motion";
export default function TechSection() {
  const allTechImages = useTechImageQuery();
  console.log("All tech images", allTechImages);
  return (
    <Container maxWidth="md" className={techStyles.container}>
      <Box className={techStyles.headingBox}>
        <motion.h2
          initial={{ scale: 0.1 }}
          whileInView={{
            scale: 1,
            transition: {
              type: "spring",
              bounce: 0.4,
              duration: 0.8,
            },
          }}
          viewport={{ once: true, amount: 0.4 }}
          className={techStyles.sectionHeading}
        >
          Our technical forte
        </motion.h2>
      </Box>
      <Grid container spacing={3}>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={4}>
          <TechCard
            imgData={allTechImages.react_img.childImageSharp.fluid}
            text="React Js"
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={4}>
          <TechCard
            imgData={allTechImages.node_img.childImageSharp.fluid}
            text="Node Js"
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={4}>
          <TechCard
            imgData={allTechImages.next_img.childImageSharp.fluid}
            text="Next Js"
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={4}>
          <TechCard
            imgData={allTechImages.angular_img.childImageSharp.fluid}
            text="Angular Js"
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={4}>
          <TechCard
            imgData={allTechImages.express_img.childImageSharp.fluid}
            text="Express Js"
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={4}>
          <TechCard
            imgData={allTechImages.vue_img.childImageSharp.fluid}
            text="Vue Js"
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={4}>
          <TechCard
            imgData={allTechImages.javascript_img.childImageSharp.fluid}
            text="Javascript"
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={4}>
          <TechCard
            imgData={allTechImages.typescript_img.childImageSharp.fluid}
            text="Typescript"
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={4}>
          <TechCard
            imgData={allTechImages.sequelize_img.childImageSharp.fluid}
            text="Sequelize"
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={4}>
          <TechCard
            imgData={allTechImages.react_native_img.childImageSharp.fluid}
            text="React Native"
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={4}>
          <TechCard
            imgData={allTechImages.flutter_img.childImageSharp.fluid}
            text="Flutter"
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={4}>
          <TechCard
            imgData={allTechImages.swift_img.childImageSharp.fluid}
            text="Swift"
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={4}>
          <TechCard
            imgData={allTechImages.figma_img.childImageSharp.fluid}
            text="Figma"
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={4}>
          <TechCard
            imgData={allTechImages.wp_img.childImageSharp.fluid}
            text="Word Press"
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={4}>
          <TechCard
            imgData={allTechImages.aws_img.childImageSharp.fluid}
            text="AWS"
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={4}>
          <TechCard
            imgData={allTechImages.dynamo_img.childImageSharp.fluid}
            text="Dynamo"
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={4}>
          <TechCard
            imgData={allTechImages.mysql_img.childImageSharp.fluid}
            text="MySQL"
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={4}>
          <TechCard
            imgData={allTechImages.mongo_img.childImageSharp.fluid}
            text="MongoDB"
          />
        </Grid>
      </Grid>
    </Container>
  );
}
