import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import Img from "gatsby-image";
import * as techStyles from "../../styles/tech.module.css";

type Props = {
  imgData: any;
  text: string;
};

export const TechCard: React.FC<Props> = ({ imgData, text }) => {
  const [hovering, setHovering] = useState<boolean>(false);
  const [hover, setHover] = useState(false);
  return (
    <Box
      onMouseOver={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      sx={hovering ? styles.cardHoverStyle : styles.cardStyle}
    >
      <Img className={techStyles.image} fluid={imgData} />
      <Typography sx={styles.textStyle}>{text}</Typography>
    </Box>
  );
};

const styles = {
  cardStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    transition: "all 250ms linear",
  },
  cardHoverStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    transform: "scale(1.1)",
    boxShadow: "0px 5px 0px 0px#4270b6",
  },
  textStyle: {
    color: "#8b8b99",
    fontSize: { xl: "18px", lg: "18px", md: "18px", sm: "0.7rem", xs: "14px" },
    lineHeight: "30px",
  },
};
