import { Box, Typography } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import { motion, Variants } from "framer-motion";
type Props = {
  Icon: any;
  title: string;
  desc: string;
};
const ServicesCard: React.FC<Props> = ({ Icon, title, desc }) => {
  const [hovering, setHovering] = useState(false);
  return (
    <Box
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      sx={styles.cardContainer}
    >
      <Box sx={styles.innerContainer}>
        <motion.div
          style={{ width: "70px", height: "70px" }}
          initial={{
            rotateY: 0,
          }}
          animate={{
            rotateY: 360,
          }}
          transition={{ repeat: Infinity, duration: 2 }}
        >
          <Icon style={hovering ? styles.hoverIcon : styles.icon} />
        </motion.div>

        <Typography sx={hovering ? styles.hoverTitle : styles.title}>
          {title}
        </Typography>
        <Typography sx={styles.desc}>{desc}</Typography>
      </Box>
    </Box>
  );
};

export default ServicesCard;

const styles = {
  cardContainer: {
    width: "100%",
    height: "100%",
    maxHeight: "370px",
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "column",
    // paddingLeft: { md: "50px" },
    // paddingBottom: "50px",
    transition: "all 0.2s linear",
    boxShadow: 2,
    borderRadius: 2,
  },
  innerContainer: {
    padding: "15px 15px",
  },
  icon: {
    height: "100%",
    width: "100%",
    maxHeight: "70px",
    maxWidth: "70px",
    padding: "10px",
    marginBottom: "5px",
    color: "#5cc2ef",
    border: "1px solid rgba(94,44,237,.2)",
    borderRadius: "4px",
    backgroundColor: "rgba(94,44,237,.05)",
    transition: "all 0.2s linear",
  },
  hoverIcon: {
    height: "100%",
    width: "100%",
    maxHeight: "70px",
    maxWidth: "70px",
    padding: "10px",
    color: "#fff",
    backgroundColor: "#5cc2ef",
    marginBottom: "5px",
  },
  title: {
    fontSize: "20px",
    lineHeight: "26px",
    color: "#282835",
    fontWeight: 600,
    paddingBottom: "5px",
    transition: "all 0.2s linear",
    marginBottom: "20px",
    marginTop: "20px",
  },
  hoverTitle: {
    fontSize: "20px",
    lineHeight: "26px",
    color: "#5cc2ef",
    fontWeight: 600,
    paddingBottom: "5px",
    marginBottom: "20px",
    marginTop: "20px",
  },
  desc: {
    color: "#8b8b99",
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: 400,
    paddingRight: "10px",
    textAlign: "justify",
  },
};
